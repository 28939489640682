import {prod} from './global.js';

    $( "#fblog" ).click(function() {
        FB.getLoginStatus(function(response) {
            if (response.status === 'connected') {
              loginFb(response.authResponse.accessToken);
            } else if (response.status === 'not_authorized') {
              // the user is logged in to Facebook, 
              // but has not authenticated your app
              login();
            } else {
              // the user isn't logged in to Facebook.
              login();
            }
        });
      });
  
      function login()
      {
          FB.login(function(response) {
            if (response.authResponse) {
              loginFb(response.authResponse.accessToken);
              } else {
                console.log('User cancelled login or did not fully authorize.');
              }
          }, {scope: 'email,user_birthday'});
      }
  
  
      // function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
      //   console.log('statusChangeCallback');
      //   console.log(response.authResponse.accessToken);                   // The current login status of the person.
      //   if (response.status === 'connected') {   // Logged into your webpage and Facebook.
          
      //   } else {                                 // Not logged into your webpage or we are unable to tell.
  
      //   }
      // }
  
  
      // function checkLoginState() {               // Called when a person is finished with the Login Button.
      //   FB.getLoginStatus(function (response) {   // See the onlogin handler
      //     statusChangeCallback(response);
      //   });
      // }
  
  

  
  
        // FB.getLoginStatus(function (response) {   // Called after the JS SDK has been initialized.
        //   statusChangeCallback(response);        // Returns the login status.
        // });
     // };
  
      function loginFb(token) {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
        const url =  prod + `/api/m/customers/loginFB?fbToken=${token}&partner=1`
        const notificatinEl = document.getElementById('notification');

        fetch(url, {
          method: 'POST',
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
  
        }).then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 500) {
            notificatinEl.textContent = 'Грешен email или парола!';
            notificatinEl.style.display = 'block';
            notificatinEl.classList.add('error');

            setTimeout(function () {
            notificatinEl.textContent = '';
            notificatinEl.style.display = 'none';
            notificatinEl.classList.remove('error');
            }, 3000);
            return;
          }
        })
          .then(data => {
            sessionStorage.setItem('customerId', data.customerId);
            sessionStorage.setItem('email', data.email);
            sessionStorage.setItem('token', data.token);
            // sessionStorage.setItem('viberPass', data.viberPass)
            sessionStorage.setItem('isFromFB', true);
            const reqUrl =  prod + `/api/m/customers/${data.customerId}`;
  
            fetch(reqUrl, {
              method: 'GET',
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                Authorization: `Basic ${data.token}`
              }
            })
              .then(res => res.json())
              .then(data => {
                sessionStorage.setItem('username', data.firstName)
                sessionStorage.setItem('phoneNumber', data.phoneNumber)
                location.href = "/home.html";
              })
            //location.href = "/home.html";
          })
      }
  